<template>
  <div>
    <b-card>
      <b-form>
        <b-form-group label="วันที่รับ">
          <date-picker
            v-model="form.start_date"
            :masks="{ input: 'D/M/YYYY' }"
            :model-config="modelConfig"
            mode="date"
            @input="passEvent"
            is-required
          >
            <template v-slot="{ inputValue, togglePopover }">
              <b-form-group>
                <!-- :mode="date-time" -->
                <b-form-input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  @click="togglePopover()"
                />
              </b-form-group>
            </template>
          </date-picker>
        </b-form-group>
        <b-form-radio-group @change="passEvent" v-model="form.start_time">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="form.start_time"
              name="some-radio"
              value="11:30:00"
            >
              หลัง 11:30
            </b-form-radio>
            <b-form-radio
              v-model="form.start_time"
              name="some-radio"
              value="14:00:00"
            >
              หลัง 14:00
            </b-form-radio>
            <b-form-radio
              v-model="form.start_time"
              name="some-radio"
              value="17:00:00"
            >
              หลัง 17:00
            </b-form-radio>
          </div>
        </b-form-radio-group>
        <b-form-group label="วันที่คืน">
          <date-picker
            v-model="form.end_date"
            :masks="{ input: 'D/M/YYYY' }"
            :model-config="modelConfig"
            mode="date"
            @input="passEvent"
            is-required
          >
            <template v-slot="{ inputValue, togglePopover }">
              <b-form-group>
                <!-- :mode="date-time" -->
                <b-form-input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  @click="togglePopover()"
                />
              </b-form-group>
            </template>
          </date-picker>
        </b-form-group>
        <b-form-radio-group @change="passEvent" v-model="form.end_time">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="form.end_time"
              name="some-radio-2"
              value="13:00:00"
            >
              ก่อน 13:00
            </b-form-radio>
            <b-form-radio
              v-model="form.end_time"
              name="some-radio-2"
              value="14:00:00"
            >
              ก่อน 14:00
            </b-form-radio>
            <b-form-radio
              v-model="form.end_time"
              name="some-radio-2"
              value="16:00:00"
            >
              ก่อน 16:00
            </b-form-radio>
            <b-form-radio
              v-model="form.end_time"
              name="some-radio-2"
              value="19:00:00"
            >
              ก่อน 19:00
            </b-form-radio>
          </div>
        </b-form-radio-group>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadioGroup,
  BForm,
  BFormInput,
  BFormRadio,
  BCard,
  BCardText,
  BCol,
  BRow,
  BButton,
  BAvatar,
  BBadge,
  BImg,
} from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import "moment-timezone";

export default {
  // props: ['carts','form2'],
  props: ["date_time_info"],
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BButton,
    BAvatar,
    BBadge,
    BImg,
    DatePicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BForm,
    BFormRadioGroup,
  },
  data() {
    return {
      form: this.date_time_info,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
    };
  },
  methods: {
    passEvent() {
      let temp = {};
      temp.start_date = moment.tz(
        this.form.start_date + " " + this.form.start_time,
        "Asia/Bangkok"
      );
      temp.end_date = moment.tz(
        this.form.end_date + " " + this.form.end_time,
        "Asia/Bangkok"
      );

      // const diff =  temp.end_date.diff(temp.start_date)
      // const duration = moment.duration(diff);
      // const hour = duration.hours();
      // const day = duration.days();
      // const minute = duration.minutes();

      this.$emit("dateForm", temp);
      // {
      //     start_date:this.form.start_date,
      //     end_date:this.form.end_date,
      //     start_time:this.form.start_time,
      //     end_time:this.form.end_time,
      // }
    },
  },
  created() {
    this.passEvent();
  },
  computed: {},
};
</script>
