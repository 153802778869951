<template>
  <div>
    <b-modal
      id="modal-add-booking"
      title="เพิ่มการจองอุปกรณ์"
      ref="modal-add-booking"
      no-close-on-backdrop
      hide-footer
    >
      <h5>เลือกอุปกรณ์</h5>
      <product-suggest @searchSelected="searchSelected"></product-suggest>
      <hr />
      <h4 class="py-1" v-if="product_selected.name">
        {{ product_selected.name }} -
        {{ product_selected.rental_price }} บาท/วัน
      </h4>
      <h5>ค่าเช่า</h5>
      <b-input-group append="฿" class="mb-1">
        <b-form-input v-model="main_order.info.total_price_normal" />
      </b-input-group>
      <div v-if="main_order.info.ready_to_book">
        <b-alert variant="success" show>
          <div class="alert-body">
            <span
              ><strong>อุปกรณ์ว่าง</strong>
              {{ product_selected.name }} ว่างสามารถลงจองได้</span
            >
          </div>
        </b-alert>
        <b-button variant="success" @click="add"> เพิ่มการจอง </b-button>
      </div>
      <!-- <b-button variant="success" @click="clear() "> Clear </b-button> -->

      <div
        v-if="
          !main_order.info.ready_to_book &&
          main_order.info.ready_to_book != null
        "
      >
        <b-alert variant="danger" show>
          <div class="alert-body">
            <span><strong>อุปกรณ์ไม่ว่าง !!!</strong></span>
          </div>
        </b-alert>
        <b-button variant="danger" @click="add"> เพิ่มการจอง </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BAlert,
} from "bootstrap-vue";
import ProductSuggest from "./ProductSuggest.vue";
import axios from "axios";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import _ from "lodash";

export default {
  components: {
    BButton,
    ProductSuggest,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BAlert,
    DatePicker,
  },
  props: ["info", "carts"],
  data() {
    return {
      form: {
        carts: [],
        info: {},
      },
      main_order: {
        carts: [],
        info: {
          ready_to_book: null,
        },
      },
      product_selected: {
        name: null,
      },
    };
  },
  created() {},
  methods: {
    searchSelected(x) {
      x.cart_id = "new_booking";
      this.form.info = this.info;
      this.form.carts = [x];
      this.check(); /// check avaliable
    },
    add() {
      this.main_order.info = this.info;
      this.$http({
        method: "POST",
        url: `/order/add-booking/`,
        data: this.main_order,
      }).then(async (x) => {
        this.$refs["modal-add-booking"].hide();
        await this.$root.$emit("order-reload");
        this.clear();
      });
    },
    clear() {
      this.product_selected = {
        name: null,
      };
      this.main_order = {
        carts: [],
        info: {
          ready_to_book: null,
        },
      };
    },
    check() {
      const temp = {};
      temp.info = {
        start_date: this.form.info.start_date,
        end_date: this.form.info.end_date,
        location: "admin_add_equipment",
      };
      ///this.form.info;
      temp.carts = _.map(
        this.form.carts,
        _.partialRight(_.pick, [
          "product_id",
          "is_kit",
          "products",
          "rental_price",
        ])
      );

      this.$http({
        method: "POST",
        url: `/queue/check-price`,
        data: temp,
      }).then((x) => {
        this.main_order = x.data.data;
      });
    },
  },
};
</script>
