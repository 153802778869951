p
<template>
  <div>
    <b-card no-body>
      <b-list-group flush>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>ยอดวางประกัน</b>
            {{ comma(orderData.deposit_price) }} บาท
            <!-- <br />Retro {{ orderData.retro_deposit_price }} บาท -->
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>ค่าเช่า</b>
            <div>
              {{ comma(orderData.rental_price) }} บาท
              <b-badge v-if="orderData.price_mode === 'auto'" variant="danger">
                Auto
              </b-badge>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>ตัวคูณค่าเช่า</b>
            <div>x{{ orderData.multiplier }}</div>
          </div>
        </b-list-group-item>
        <div v-if="orderData.discount_price > 0">
          <b-list-group-item>
            <div class="d-flex justify-content-between">
              <b>ส่วนลดค่าเช่า</b>
              {{ comma(orderData.discount_price) }} บาท
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="d-flex justify-content-between">
              <b>ค่าเช่าลดแล้ว</b>
              {{ comma(orderData.rental_price - orderData.discount_price) }} บาท
            </div>
          </b-list-group-item>
        </div>

        <b-list-group-item v-if="orderData.has_withholding_tax_price">
          <div class="d-flex justify-content-between">
            <b>หัก ณ ที่จ่าย</b>
            {{ comma(orderData.withholding_tax_price) }} บาท
          </div>
        </b-list-group-item>

        <b-list-group-item v-if="orderData.shipping_price > 0">
          <div class="d-flex justify-content-between">
            <b>ค่าส่ง</b>
            {{ comma(orderData.shipping_price) }} บาท
          </div>
        </b-list-group-item>

        <b-list-group-item v-if="orderData.require_advance_transfer">
          <div class="d-flex justify-content-between">
            <h4>ยอดที่ต้องโอนล่วงหน้า</h4>
            <div class="text-right">
              <h4>{{ comma(orderData.advance_transfer_price) }} บาท</h4>
              <b-badge variant="danger">แจ้งโอนล่วงหน้า</b-badge>
            </div>
          </div>
        </b-list-group-item>

        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <h4>ยอดชำระ</h4>
            <h4>{{ comma(orderData.total_net_price) }} บาท</h4>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            การชำระเงิน
            <b-badge v-if="orderData.is_paid" variant="success"
              >รับเงินครบแล้ว
            </b-badge>
            <b-badge v-else variant="danger">ยังไม่ได้รับเงิน</b-badge>
          </div>
        </b-list-group-item>

        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>วัน-เวลารับ</b>
            <div>
              <h5>
                {{ orderData.start_date | day }} |
                <b-badge variant="success">{{
                  orderData.start_date | time
                }}</b-badge>
              </h5>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>วัน-เวลาคืน</b>
            <div>
              <h5>
                {{ orderData.end_date | day }} |
                <b-badge variant="success">{{
                  orderData.end_date | time
                }}</b-badge>
              </h5>
            </div>
          </div>
        </b-list-group-item>

        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>รูปแบบการรับของ</b>
            <b-badge :variant="orderData.pickup_method_text.variant">{{
              orderData.pickup_method_text.text
            }}</b-badge>
          </div>
        </b-list-group-item>

        <b-list-group-item v-if="orderData.pickup_method !== 'self'">
          <div class="">
            <b>ที่อยู่จัดส่ง</b>
            <div v-if="orderData.shipping">
              {{ orderData.shipping.formatted_address }} <br />
              <br />
              <!-- ค่าส่ง : {{ orderData.shipping.shipping_cost }} <br /> -->
              พิกัด : {{ orderData.shipping.shipping_lat }} ,
              {{ orderData.shipping.shipping_lng }} <br />
            </div>
          </div>
        </b-list-group-item>

        <!-- <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>สร้าง Order โดย</b>
            {{ orderData.create_by }}
          </div>
        </b-list-group-item> -->

        <b-list-group-item v-if="orderData.note_admin">
          <div class="d-flex justify-content-between">
            <b>Note หลังร้าน</b>
            <h4>{{ orderData.note_admin }}</h4>
          </div>
        </b-list-group-item>

        <b-list-group-item v-if="orderData.note_deposit">
          <div class="d-flex justify-content-between">
            <b>Note วางประกัน</b>
            {{ orderData.note_deposit }}
          </div>
        </b-list-group-item>

        <b-list-group-item v-if="orderData.note_user">
          <div class="d-flex justify-content-between">
            <b>Note ลูกค้า</b>
            {{ orderData.note_user }}
          </div>
        </b-list-group-item>

        <b-list-group-item v-if="orderData.is_extend">
          <div class="d-flex justify-content-between">
            <b>เช่าต่อจาก</b>
            <a href="#" @click="open_new(orderData.extend_from)">{{
              orderData.extend_from
            }}</a>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <b>ทำรายการเมื่อ</b>
            {{ orderData.createdAt | day }} -
            {{ orderData.createdAt | time }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <div>ใบจองหมดอายุ</div>
            <div>
              <span class="mr-1">
                {{ orderData.pre_book_expire_date | day }} -
                {{ orderData.pre_book_expire_date | time }}
              </span>
              <b-button
                size="sm"
                variant="relief-warning"
                @click="collapses1 = !collapses1"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </div>
          <b-collapse v-model="collapses1">
            <div class="text-right">
              <hr />
              <date-picker
                v-model="pre_book_expire_date"
                mode="dateTime"
                @input="saveExpireData"
                is24hr
                :minute-increment="15"
                is-required
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <!-- :mode="date - time" -->
                  <b-form-input
                    autocomplete="off"
                    class="border px-2 rounded"
                    :value="inputValue"
                    @click="togglePopover()"
                  />
                </template>
              </date-picker>
            </div>
          </b-collapse>
        </b-list-group-item>

        <b-list-group-item>
          <!-- <div class="d-flex justify-content-between">
            https://www.goprolineup.com/orders/pre/{{ orderData.order_hash }}
          </div> -->
          <div class="d-flex justify-content-between">
            https://www.lenslineup.com/invoice/{{ orderData.order_hash }}
            <b-button
              size="sm"
              variant="relief-warning"
              @click="
                copyToClipboard(
                  `https://www.lenslineup.com/invoice/${orderData.order_hash}`
                )
              "
              >Copy</b-button
            >
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <!-- modal -->
    <change-date-modal
      :info="orderData"
      v-if="(orderData._id != null) & (booking.length > 0)"
    ></change-date-modal>
    <edit-order-modal
      :info="orderData"
      v-if="(orderData._id != null) & (booking.length > 0)"
    >
    </edit-order-modal>
    <change-member-modal :info="orderData" />
    <change-status-modal :info="orderData" />
    <extend-rental-modal :info="orderData" />

    <b-modal
      id="modal-change-pickup-status"
      title="เปลี่ยนสถานะการรับอุปกรณ์"
      ref="modal-change-pickup-status"
      hide-footer
    >
      <!-- no-close-on-backdrop -->
      <b-overlay :show="overlay" rounded="sm" variant="dark">
        <order-pickup-status-switch
          @close="close"
          @loading="
            (x) => {
              this.overlay = x.loading;
            }
          "
          :info="orderData"
        ></order-pickup-status-switch>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BSpinner,
  BOverlay,
  BCard,
  BCardText,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BAvatar,
  BTable,
  BCollapse,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import ChangeDateModal from "./ChangeDateModal.vue";
import EditOrderModal from "./EditOrderModal.vue";
import ChangeMemberModal from "./ChangeMemberModal.vue";
import ChangeStatusModal from "./ChangeStatusModal.vue";
import ExtendRentalModal from "./ExtendRentalModal.vue";
import OrderPickupStatusSwitch from "./OrderPickupStatusSwitch.vue"; /// สถานะ Order
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    BSpinner,
    BOverlay,
    BCard,
    BCardText,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    DatePicker,
    BButton,
    BAvatar,
    BTable,
    BCollapse,
    ChangeDateModal,
    EditOrderModal,
    ChangeMemberModal,
    ChangeStatusModal,
    OrderPickupStatusSwitch,
    ExtendRentalModal,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: ["info", "booking"],
  watch: {
    info: function (newVal, oldVal) {
      this.orderData = newVal;
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pre_book_expire_date: null,
      orderData: {
        _id: null,
        pickup_method: null,
        shipping: {
          formatted_address: null,
        },
      },
      overlay: false,
      loading: {
        price_reload: false,
      },
      collapses1: false,
    };
  },
  created() {
    this.orderData = this.info;
    this.pre_book_expire_date = this.orderData.pre_book_expire_date;
  },
  methods: {
    saveExpireData() {
      // date;
      console.log("save expore date");

      this.$http({
        method: "PUT",
        url: "/order/" + this.$route.params.id,
        data: {
          pre_book_expire_date: this.pre_book_expire_date,
        },
      })
        .then((x) => {
          // this.toast("แก้ไข Order", "แก้ไข Order เรียบร้อยแล้ว", "success");
          this.toast_success("แก้วันที่หมดอายุใบจอง");
          this.$root.$emit("order-reload");
        })
        .catch((error) => {
          this.toast_error("มีข้อผิดพลาด");
        });
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            this.toast_success("Copy แล้ว");
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        console.warn("Clipboard API not supported");
      }
    },
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    close() {
      this.$refs["modal-change-pickup-status"].hide();
    },
    comma(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    showDeleteConfirmBox() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ลบ Order และคิวนี้ทั้งหมด", {
          title: "ลบ Order",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http({
              method: "DELETE",
              url: `/order/${this.info.order_id_text}`,
            }).then((x) => {
              this.$router.push({ name: "order-list" });
            });
          }
        });
    },
  },
  filters: {
    day: function (date) {
      if (date) {
        return moment(date).format("D/M/YYYY");
      }
    },
    time: function (date) {
      if (date) {
        return moment(date).format("HH:mm");
      }
    },
  },
};
</script>

<style></style>
