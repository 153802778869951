<template>
  <div>
    <div v-if="booking_kit_list.length">
      <div
        class="d-flex justify-content-between"
        v-for="(xx, index) of booking_kit_list"
        :key="index"
      >
        <div>
          <b-list-group flush>
            <b-list-group-item>
              <h4>
                {{ index + 1 }}. {{ xx.web_name }} -
                {{ xx.rental_price | number }} บาท/วัน
              </h4>

              <ul>
                <li v-for="(yy, index2) of xx.products" :key="index2">
                  <h5>
                    {{ yy.web_name }}
                    - ราคาชุด {{ yy.set_price | number }} บาท / วัน
                  </h5>
                </li>
              </ul>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <h4>{{ xx.booking_price | number }} บาท</h4>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-danger"
              class="btn-icon mr-1 mb-1"
              @click="deleteBookingKit(xx)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-list-group flush>
        <b-list-group-item>
          <h4>ไม่มีอุปกรณ์จัดชุด</h4>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>
<script>
import { BListGroup, BListGroupItem, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
  },
  directives: {
    Ripple,
  },
  mounted() {
    /// เปลี่ยนมาใช้อันนี้ให้หมด
    this.$root.$on("order-reload", async () => {
      await this.load_kit_data();
    });
  },
  data() {
    return {
      product_fields: [
        { key: "product_name", label: "รายการ" },
        { key: "product_sub.serial", label: "Serial" },
        { key: "price", label: "ค่าเช่า" },
        "Action",
      ],
      booking_kit_list: [],
    };
  },
  created() {
    this.load_kit_data();
  },
  methods: {
    load_kit_data() {
      this.$http({
        method: "POST",
        url: `/order/booking/list-kit`,
        data: { order_id: this.$route.params.id },
      }).then((x) => {
        this.booking_kit_list = x.data.data;
      });
    },
    deleteBookingKit(booking_kit) {
      const booking_kit_id = booking_kit._id;
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(`ลบอุปกรณ์จัดชุด ${booking_kit.web_name}`, {
          title: "ลบอุปกรณ์จัดชุด",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http({
              method: "DELETE",
              url: `/order/booking-kit/${booking_kit_id}`,
            }).then((x) => {
              this.$root.$emit("order-reload");
            });
          }
        });
    },
  },
  filters: {
    number(x) {
      if (x != null) {
        x = parseFloat(x);
        // Check if the number is an integer
        if (Number.isInteger(x)) {
          // If it's an integer, return it without any decimal places
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          // If it's not an integer, format it to two decimal places
          return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else {
        return x;
      }
    },
  },
};
</script>
