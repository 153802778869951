<template>
  <div>
    <div class="d-flex justify-content-between">
      <!-- <h4>กันวงเงิน</h4> -->
    </div>
    <b-row>
      <b-col>
        <b-card>
          <h4>รวม Order</h4>
          <div class="d-flex justify-content-between">
            <b-form-input
              type="text"
              v-model="new_order_id"
              class="mr-1"
              autocomplete="off"
              value="654f9512aa8d136512471225"
            >
            </b-form-input>
            <b-button
              variant="relief-primary"
              @click="search_order()"
              v-if="!loading"
            >
              <feather-icon slot="button-content" icon="SearchIcon" />
            </b-button>

            <b-button variant="relief-primary" disabled v-if="loading">
              <b-spinner small />
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="combineable">
          <h4>รายการที่จะรวม</h4>
          <div v-for="item in booking" :key="item._id">
            <div class="row">
              <div class="col-9">
                {{ item.product.name }} ตัวที่ {{ item.product_sub.number }}
                <br />
                <small> ({{ item.product_sub.serial }})</small>
              </div>
              <div class="col-3 text-right">
                <b-form-input
                  type="text"
                  class="mr-1"
                  autocomplete="off"
                  v-model="item.price"
                ></b-form-input>
              </div>
            </div>
            <hr />
          </div>

          <h4>ค่าใช้จ่ายใน Order</h4>
          <div class="row mb-1">
            <div class="col-6">ค่าเช่าเพิ่ม</div>
            <div class="col-6">
              <b-form-input
                type="text"
                class="mr-1"
                autocomplete="off"
                v-model="form.rental_price"
              ></b-form-input>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-6">เงินประกันเพิ่ม</div>
            <div class="col-6">
              <b-form-input
                type="text"
                class="mr-1"
                autocomplete="off"
                v-model="form.deposit_price"
              ></b-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <b-button
                variant="relief-success"
                class="btn-icon"
                @click="confirmCombine()"
              >
                รวมสัญญา
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BContainer,
  BFormInput,
  BFormGroup,
  BButton,
  BCard,
  BCol,
  BRow,
  BImg,
  BFormCheckbox,
  BForm,
  BTable,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BContainer,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCol,
    BRow,
    BImg,
    BButton,
    BFormCheckbox,
    BTable,
    BListGroup,
    BListGroupItem,
    BSpinner,
  },
  mounted() {},
  data() {
    return {
      new_order_id: "",
      form: {},
      booking_price: [],
      order: {},
      booking: {},
      combineable: false,
      loading: false,
    };
  },
  methods: {
    search_order() {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/order/pre-combine`,
        data: {
          main_order_id: this.$route.params.id,
          new_order_id: this.new_order_id,
        },
      })
        .then(async (x) => {
          this.loading = false;
          this.combineable = x.data.data.combineable;
          if (this.combineable) {
            this.order = x.data.data.order;
            this.booking = x.data.data.booking;
            this.form.rental_price = this.order.rental_price;
            this.form.deposit_price = this.order.deposit_price;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
    confirmCombine() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("รวมสัญญา", {
          title: "ยืนยันรวมสัญญา !!!",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const temp_form = {
              new_order: this.order,
              new_booking: this.booking,
              main_order_id: this.$route.params.id,
              order_form: this.form, /// ค่าเช่า ค่ามัดจำ user input
            };
            this.$http({
              method: "POST",
              url: `/order/combine`,
              data: temp_form,
            }).then((x) => {
              this.combineable = false;
              this.new_order_id = null;
              this.$root.$emit("order-reload");
            });
          }
        });
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
